/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled, Text } from "theme-ui"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Hero,
  Overlay,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import { ColorBar } from "../../components/elements"

const HospitalStay = ({ data, pageContext }) => {
  const page = data.datoCmsHospitalStayPage
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Main>
        <Hero>
          <BackgroundImage Tag="div" fluid={page.heroImage.fluid}>
            <Container
              sx={{ position: "relative", px: "1em", py: [5, 6], zIndex: 10 }}
            >
              <Styled.h1>{page.title}</Styled.h1>
              <Breadcrumb
                crumbs={crumbs}
                crumbSeparator=" / "
                crumbLabel={page.title}
              />
            </Container>
            <Overlay sx={{ opacity: 1 }} />
          </BackgroundImage>
          <ColorBar sx={{ position: "absolute", bottom: 0, left: 0 }} />
        </Hero>

        {/* IntroContent */}
        <Section id="intro">
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
              <Box sx={{ width: ["full", "full", "4/5"] }}>
                <Styled.h6 as="p">{page.introContent}</Styled.h6>
              </Box>
              <Text
                sx={{ width: ["full", "full", "4/5"] }}
                dangerouslySetInnerHTML={{
                  __html: page.contentNode.childMarkdownRemark.html,
                }}
              />
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default HospitalStay

export const query = graphql`
  {
    datoCmsHospitalStayPage {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      id
      introContent
      slug
      title
      heroImage {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        tags
      }
    }
  }
`
